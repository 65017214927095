/* eslint-disable react-native/no-inline-styles */
// import {COLORS, DICES, DIGIT} from './bets-shard.variable';
// import {View, StyleSheet} from 'react-native';
import {View} from 'react-native';
// import {basicColor, flex, padding} from '@/components/style';
import React from 'react';
// import LazyImage, {LazyImageBackground} from '@/components/basic/image';
// const styles = StyleSheet.create({
//   container: {
//     columnGap: 2,
//   },
// });
import Text from '@basicComponents/text';
import ResultItem from './result-item';
// import {BasicObject, SafeAny} from '@/types';
import {SafeAny} from '@/types';
import {ScrollView} from 'react-native-gesture-handler';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import {Svg, Path} from 'react-native-svg';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {useToast} from '@/components/basic/modal';
import Price from '@/components/business/price';
import {NumberNode, getGameType} from './bets-table-item/dice';
import {ColorNumber} from './bets-table-item/color';
import {ABCNumber} from './bets-table-item/digit';
import {MatkaNumber} from './bets-table-item/matka';
import {CNumber, CType} from './bets-table-item/comprehensive';

const getPayment = (item: SafeAny) => {
  return (
    <Price
      price={item.amount}
      textProps={{
        size: 'medium',
      }}
    />
  );
};

const BetsShardtable = ({orderInfo, gameCode}: SafeAny) => {
  const len = orderInfo?.list?.length || 0;
  const {calcActualSize, screenHeight} = useScreenSize();
  const [isMore, setIsMore] = React.useState(false);
  const {renderModal, show} = useToast();
  const None = (
    <View
      style={[
        theme.flex.flex,
        theme.flex.col,
        theme.flex.alignEnd,
        {gap: calcActualSize(2)},
      ]}>
      <Text calc second fontWeight="500" textAlign="center">
        Won
      </Text>
      <Text
        calc
        color={theme.fontColor.main}
        fontFamily="fontDin"
        fontSize={14}
        fontWeight="700"
        textAlign="center">
        ...
      </Text>
    </View>
  );

  const render = (item: SafeAny) => {
    const base = [getPayment(item), None];
    switch (gameCode) {
      case 'dice': {
        return [<NumberNode item={item} />, getGameType(item), ...base];
      }
      case 'color': {
        return [<ColorNumber item={item} />, ...base];
      }
      case '3Digit':
      case 'quick3d': {
        return [<ABCNumber items={item} />, ...base];
      }
      case 'matka': {
        return [<MatkaNumber items={item} />, ...base];
      }
      case 'quickStateLottery':
      case 'stateLottery': {
        return [<CNumber items={item} />, <CType items={item} />, ...base];
      }
    }
  };
  return (
    <View
      style={[
        {
          paddingHorizontal: calcActualSize(12),
        },
      ]}>
      <ScrollView
        horizontal={true}
        style={[
          {
            width: '100%',
            borderRadius: calcActualSize(8),
          },
        ]}>
        <View>
          <ResultItem gameCode={gameCode} height={40} />
          <ScrollView
            style={[
              {
                maxHeight: calcActualSize(screenHeight > 700 ? 240 : 144),
                backgroundColor: '#fff',
              },
            ]}>
            {!!orderInfo?.list &&
              orderInfo.list
                .slice(0, isMore ? undefined : screenHeight > 700 ? 5 : 3)
                .map((item: SafeAny, index: number) => {
                  return (
                    <ResultItem
                      height={48}
                      gameCode={gameCode}
                      key={index}
                      backgroundColor={index % 2 !== 0 ? '#F8F9FF' : '#fff'}
                      renderList={render(item)}
                    />
                  );
                })}
          </ScrollView>
        </View>
      </ScrollView>
      {len > (screenHeight > 700 ? 5 : 3) && (
        <NativeTouchableOpacity
          onPress={() => {
            setIsMore(!isMore);
            if (!isMore) {
              show({
                type: 'success',
                message: 'Scroll the table to see more',
              });
            }
          }}
          style={[
            theme.flex.flex,
            theme.flex.row,
            theme.flex.center,
            theme.background.white,
            {
              paddingHorizontal: calcActualSize(12),
              paddingVertical: calcActualSize(8),
              marginTop: calcActualSize(8),
              gap: calcActualSize(4),
            },
          ]}>
          <Text calc fontSize={14} color={'#000'} blod>
            {isMore ? 'Less' : 'More'}
          </Text>
          <View style={[{transform: [{rotate: isMore ? '180deg' : '0'}]}]}>
            <Svg
              width={calcActualSize(14)}
              height={calcActualSize(15)}
              viewBox="0 0 14 15"
              fill="none">
              <Path
                d="M3.5 5.8457L7 9.3457L10.5 5.8457"
                stroke="#9FA5AC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </Svg>
          </View>
        </NativeTouchableOpacity>
      )}
      {renderModal}
    </View>
  );
};
export default BetsShardtable;

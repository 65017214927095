/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@/components/basic/text';
import {SafeAny} from '@/types';
import {COLORS, RESULT_INDEX} from './constant';
import {checkType} from '@/common-pages/mix-lottery/mix-lottery-service';
import Tag from '@/common-pages/mix-lottery/component/tag';
import DigitBall from '@/common-pages/mix-lottery/component/digit-ball';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const DEFAULT = ['A', 'B', 'C', 'D'];
const SIDE_TYPE = ['Odd', 'Even', 'Big', 'Small'];
const F_P_C_TYPE = ['Fish', 'Prawn', 'Crab'];

const styles = StyleSheet.create({
  number: {
    flex: 1,
  },
  time: {
    flex: 1,
    alignItems: 'center',
  },
});

export const CNumber = ({items}: {items: SafeAny}) => {
  const {calcActualSize} = useScreenSize();
  const {modeID, betItem, betNo} = items;
  const tagInfo = React.useMemo(() => {
    if (modeID === 1) {
      if (betItem) {
        const TYPE_INDEX = (betItem - 1) % 4;
        const INDEX = Math.trunc((betItem - 1) / 4);
        return {
          type: DEFAULT[INDEX],
          label: SIDE_TYPE[TYPE_INDEX],
        };
      }
    }
    if (modeID === 9) {
      const TYPE_INDEX = (betItem - 1) % 3;
      return {
        type: 'D',
        label: F_P_C_TYPE[TYPE_INDEX],
      };
    }
    return {};
  }, [modeID, betItem]);
  const betInfo = React.useMemo(() => {
    if (checkType(modeID).isx4) {
      const indexs = RESULT_INDEX.slice(-4);
      return betNo
        .slice(-4)
        .split('')
        .map((item: SafeAny, _i: number) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx3) {
      const indexs = RESULT_INDEX.slice(-3);
      return betNo
        .slice(-3)
        .split('')
        .map((item: SafeAny, _i: number) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx2) {
      const indexs = RESULT_INDEX.slice(-2);
      return betNo
        .slice(-2)
        .split('')
        .map((item: SafeAny, _i: number) => {
          return {
            number: item,
            bg: COLORS[indexs[_i]],
          };
        });
    }
    if (checkType(modeID).isx1) {
      return betNo
        .slice(-1)
        .split('')
        .map((item: SafeAny, _i: number) => {
          return {
            number: item,
            bg: theme.basicColor.primary,
          };
        });
    }
    return [];
  }, [modeID, betNo]);
  return (
    <>
      {modeID === 9 || modeID === 1 ? (
        <View
          style={[
            theme.flex.row,
            {gap: calcActualSize(theme.paddingSize.xxs)},
            styles.number,
          ]}>
          {tagInfo.type && (
            <Tag label={tagInfo.label} backgroundColor={COLORS[tagInfo.type]} />
          )}
        </View>
      ) : (
        <View
          style={[
            theme.flex.row,
            {gap: calcActualSize(theme.paddingSize.xxs)},
            styles.number,
          ]}>
          {betInfo.map((item: SafeAny, i: number) => (
            <DigitBall
              isPrimary={checkType(modeID).isX}
              key={i}
              digit={item.number}
              bg={item.bg}
              type={'A'}
            />
          ))}
        </View>
      )}
    </>
  );
};

export const CType = ({items}: {items: SafeAny}) => {
  const {calcActualSize} = useScreenSize();
  const {modeName, tabName} = items;
  const getName = () => {
    const tab = tabName.split('-').join(' ');
    if (modeName === 'FishPrawnCrab') {
      return `F\\P\\C-${tab}`;
    }
    return `${modeName}-${tab}`;
  };
  return (
    <View
      style={[
        theme.flex.flex,
        theme.flex.col,
        theme.flex.center,
        {gap: calcActualSize(2), width: '90%'},
      ]}>
      <Text calc second fontSize={14} fontWeight="500" textAlign="center">
        {getName()}
      </Text>
    </View>
  );
};

/* eslint-disable react-native/no-inline-styles */
import {
  LinearGradientBetsSharColor,
  shardImg,
  Subtract,
} from './bets-shard.variable';
import {View, ImageBackground} from 'react-native';
import {flex} from '@/components/style';
import React from 'react';
import LazyImage from '@/components/basic/image';

import {SafeAny} from '@/types';
import LinearGradient from '@basicComponents/linear-gradient';
import BetsShardUser from './bets-shard-user';
import BetsShardInfo from './bets-shard-info';
import Text from '@basicComponents/text';
// import BetsShardWinningModal from './bets-shard-winning-modal';
import BetsShardtable from './bets-shard-table';
import theme from '@/style';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const BetsShardHome = ({params, orderInfo, userInfo}: SafeAny) => {
  const {calcActualSize, screenHeight, screenWidth} = useScreenSize();

  return (
    <>
      <ImageBackground
        style={[
          {
            width: screenWidth,
            height: screenHeight,
          },
          theme.position.abs,
        ]}
        source={{uri: shardImg}}
      />
      <View
        style={[
          {width: '100%', padding: calcActualSize(theme.paddingSize.xxl)},
        ]}>
        <LinearGradient
          style={[
            {
              width: '100%',
              borderTopLeftRadius: calcActualSize(8),
              borderTopRightRadius: calcActualSize(8),
              paddingHorizontal: calcActualSize(theme.paddingSize.xxl),
              paddingVertical: calcActualSize(theme.paddingSize.s),
            },
          ]}
          start={{x: 0, y: 0}}
          end={{x: 0, y: 1}}
          colors={LinearGradientBetsSharColor}>
          <BetsShardUser userInfo={userInfo} notDrawn />
        </LinearGradient>
        <BetsShardInfo
          backgroundColor={'#fff'}
          name={'Color-5 miute'}
          result={
            <Text calc second fontWeight="500" fontSize={12}>
              To be drawn...
            </Text>
          }
          orderInfo={orderInfo}
        />
        <View
          style={[
            {
              height:
                screenHeight > 700 ? calcActualSize(367) : calcActualSize(271),
              backgroundColor: '#FFEEB4',
              borderTopLeftRadius: calcActualSize(12),
              borderTopRightRadius: calcActualSize(12),
            },
          ]}>
          <LinearGradient
            style={[
              {
                width: '100%',
                borderTopLeftRadius: calcActualSize(12),
                borderTopRightRadius: calcActualSize(12),
                height: calcActualSize(41),
              },
              flex.center,
            ]}
            start={{x: 0, y: 0}}
            end={{x: 0, y: 1}}
            colors={['#FFF', 'rgba(255, 255, 255, 0.00)']}>
            <Text calc fontSize={14} color={'#000'} blod>
              Bets
            </Text>
          </LinearGradient>
          <View
            style={[
              {
                height:
                  screenHeight > 700
                    ? calcActualSize(326)
                    : calcActualSize(230),
              },
            ]}>
            <BetsShardtable orderInfo={orderInfo} gameCode={params?.gameCode} />
          </View>
          {/* <View style={[{marginTop: -1}]}> */}
          <View>
            <LazyImage
              imageUrl={Subtract}
              occupancy="#0000"
              width={'100%'}
              height={calcActualSize(24)}
            />
          </View>
        </View>
      </View>
      {/* <BetsShardWinningModal
        ref={BetsShardWinningModalRef}
        userInviteCode={userInfo?.userInviteCode}
      /> */}
    </>
  );
};
export default BetsShardHome;
